import { useEffect, useState } from "react";

const useSectionInView = (sectionRef) => {
  const [appear, setAppear] = useState(false);
  // const [offsetY, setOffsetY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      var scrolledFromTop = window.pageYOffset;
      if (sectionRef) {
        var sectionStartingPoint =
          sectionRef?.current?.offsetTop - window.innerHeight + 200;
        // sectionRef?.current?.offsetTop - window.innerHeight + 320;

        if (scrolledFromTop >= sectionStartingPoint) setAppear(true);
        else setAppear(false);
      }
      // setOffsetY(scrolledFromTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sectionRef]);

  return {
    appear,
    //  offsetY
  };
};

export default useSectionInView;
