import React from "react";

const Impressum = () => {
  return (
    <section id='Impressum' href='#Impressum'>
      <div className='Impressum-Inhalt'>
        <br />
        <h4>Impressum</h4>

        <p>
          derHaus Ismaili
          <br />
          Poststrasse 4<br />
          8406 Winterthur
          <br />
          Zurich-Swizerland
          <br />
          ismaili@derhaus.ch
        </p>

        <h4>Verantwortlich für den Inhalt</h4>
        <p>derHaus Ismaili</p>

        <h4>Urheberrecht</h4>
        <p>
          Die Inhalte dieser Website sind urheberrechtlich geschützt. Jede
          Verwendung außerhalb der engen Grenzen des Urheberrechtsgesetzes ist
          ohne Zustimmung des Unternehmens unzulässig.
        </p>

        <h4>Haftung für Links</h4>
        <p>
          Diese Website enthält Links zu externen Websites Dritter, auf deren
          Inhalte das Unternehmen keinen Einfluss hat. Daher kann das
          Unternehmen für diese fremden Inhalte auch keine Gewähr übernehmen.
          Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
          oder Betreiber der Seiten verantwortlich.
        </p>
      </div>
    </section>
  );
};

export default Impressum;
