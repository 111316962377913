import React, { useRef } from "react";
import useSectionInView from "../hooks/useSectionInView";
import { EmailOutlined, Instagram } from "@mui/icons-material";

const Contact = () => {
  const contactRef = useRef();
  const { appear } = useSectionInView(contactRef);

  return (
    <section
      id='Kontakt'
      ref={contactRef}
      className={appear ? "kontakt-animations" : "kontakt-animations-out"}
    >
      <a href='mailto:ismaili@derhaus.ch' style={{ animationDelay: "0.05s" }}>
        <EmailOutlined />
        ismaili@derhaus.ch
      </a>
      <a
        href='https://www.instagram.com/derhaus.ch/'
        target='_blank'
        rel='noopener noreferrer'
        style={{ animationDelay: "0.1s" }}
      >
        <Instagram />
        @derhaus.ch
      </a>
    </section>
  );
};

export default Contact;
