import {
  p0101,
  p0102,
  p0103,
  p0104,
  p0105,
  p0106,
  p0201,
  p0202,
  p0203,
  p0204,
  p0205,
  p0206,
  p0207,
  p0301,
  p0302,
  p0303,
  p0304,
  p0305,
  p0306,
  p0307,
  p0308,
  p0309,
  p0310,
  p0311,
  p0312,
  p0313,
  p0401,
  p0402,
  p0403,
  p0404,
  p0405,
  p0406,
  p0407,
  p0408,
  p0409,
  p0410,
  p0501,
  p0502,
  p0503,
  p0504,
  p0505,
  p0506,
  p0507,
  p0508,
  p0509,
  p0510,
  p0601,
  p0602,
  p0603,
  p0604,
  p0605,
  p0606,
  p0607,
  p0608,
  p0609,
  p0701,
  p0702,
  p0703,
  p0704,
  p0801,
  p0806,
  p0807,
  p0802,
  p0803,
  p0804,
  p0805,
  p0808,
  p0809,
  p0901,
  p0902,
  p0903,
  p0904,
  p0905,
  p0906,
} from "../utils/projectImages";
import {
  p01,
  p02,
  p03,
  p04,
  p05,
  p06,
  p07,
  p08,
  p09,
} from "../utils/imagesPaths";

const allProjects = [
  {
    name: "Ein Haus für Identität",
    thumb: p09,
    images: [p0901, p0902, p0903, p0904, p0905, p0906],
    description:
      "Nach dem Bottom-up-Prinzip, der Befragung der Quartierbewohner und der genauen Beobachtung des Umfeldes haben wir in der Stadt Dietikon zwischen Dreispitz und Stadtzentrum folgendes festgestellt: Die fehlende Identität, die Unsicherheit der Bewohner in Bezug auf die Aufenthalte und die bereits vorhandenen Qualitäten führten zu der These, dass hier ein Pendant zum zukünftigen Lägernplatz direkt an der Haltestelle der Limmattalbahn benötigt wird. Das 80er-Jahre-Gebäude, in dem bereits der türkische Einzelhändler im Erdgeschoss das Quartier versorgt, soll sowohl öffentliche Nutzungen als auch preisgünstigen Wohnraum beherbergen. Die verdrängten Bewohner der Großwohnsiedlung sollen im Quartier bleiben und ihre Wurzeln vertiefen. Eine einseitige Kaphaltestelle bildet den notwendigen Vorplatz für das Quartiergebäude und bietet Funktionen wie Bazar, Samstagsmarkt und Aufenthaltsmöglichkeiten.",
    Entwurf: "2024",
    Referenz: "Bottom-up",
    Auftrag: 'Master-Studium ZHAW "Urban Project"',
  },
  {
    name: "Schulanlage in Hybrid-Bauweise",
    thumb: p08,
    images: [p0801, p0802, p0803, p0804, p0805, p0806, p0807, p0808, p0809],
    description:
      "Eine neue Schulanlage an der Badenerstrasse in Zürich soll tagsüber als Schule und abends für öffentliche Funktionen genutzt werden. Die Platzierung des Gebäudes ist aus verkehrstechnischen und historischen Gründen sinnvoll, da sich früher ein Verbindungsweg auf dem Grundstück befand. Obwohl dieser Weg nicht mehr existiert, ist seine frühere Lage im Schwarzplan spürbar, was die diagonale Anordnung des Gebäudes an dieser Stelle plausibel macht. Die Konstruktion des Gebäudes basiert auf einer Holz-Steckverbindung mit Betonkernen und einer Deckenkonstruktion aus Brettstapelelementen in Verbindung mit geschraubten Gneisplatten. Die Gruppenräume sind multifunktional und lassen unterschiedliche Zusammensetzungen zu.",
    Entwurf: "2023",
    Referenz: 'KI-Bild "midjourney.com"',
    Auftrag: 'Master-Studium ZHAW "Constructive Project"',
  },
  {
    name: "Stadtfoyer in Lyon",
    thumb: p05,
    images: [
      p0501,
      p0502,
      p0503,
      p0504,
      p0505,
      p0506,
      p0507,
      p0508,
      p0509,
      p0510,
    ],
    description:
      "Hier war die Aufgabe ein Stadtquartier mit einem vorgegebenen Raumprogramm zu unterbringen. Gleichzeitig sollte das Gebäude ein Gegenüber zur Kathedrale bilden. Durch das Platzieren des Quartiersaals in den Untergrund, kann das oberirdische Volumen profitieren. U-förmige Grundrisse aufeinander gestapelt und gedreht, bilden einen gemeinsamen Nenner, welcher als Innenhof dient. Das Dachgeschoss setzt in der Höhe einen Abschluss und wirkt als das Privatgeschoss. Der Innenhof als zentrales Element, abgesehen von seinem Mehrwert, bindet das Gesamtwerk zusammen.",
    Entwurf: "2019",
    Referenz: "Prolog-Bild Akam Ismaili",
    Auftrag: "Bachelor-Studium ZHAW",
  },
  {
    name: "Spielhalle Winterthur",
    thumb: p01,
    images: [p0101, p0102, p0103, p0104, p0105, p0106],
    description:
      "Eine Spielhalle, worin hauptsächlich getanzt wird, sollte im Rosengarten der Stadt Winterthur geplant werden. Anhand eines Knotenpunktes wurde die Halle entworfen und konstruiert. Die Lage der Halle sollte von uns Studierenden ebenfalls kontextuell und individuell bestimmt werden. Mein Schwerpunkt bei dieser Aufgabe lag darin, mit einer einzigen steifen Rahmen die restliche Rahmen, die in einer bestimmten Richtung gerichtet waren, auszusteifen.",
    Entwurf: "2017",
    Referenz: "-",
    Auftrag: "Bachelor-Studium ZHAW",
  },
  {
    name: "Dorfplatz Steffisburg",
    thumb: p07,
    images: [p0701, p0702, p0703, p0704],
    description:
      "In der Idee des zukünftigen Dorfplatzes wird der gesamte Bauperimeter als ein Ort für die Öffentlichkeit betrachtet. Sowohl der Platz als auch die Bebauungen unter- und oberhalb der Perimeter weisen öffentliche Nutzungen auf. Der gedeckte Platz als Zeichen und einladendes Element zum Aufenthalt wird zur Haupstrasse hin zwischen zwei Bebauungen platziert. Darunter befindet sich das neue Kunsthaus Steffisburg und über den transluzenten Bodenaufbau kann ein diffuses Licht bis ins Innere durchdringen. Als vis-à-vis und sekundärer Zugang befindet sich an der Spitze des Dreiecks ein Platz-Pavillon, welcher Aufenthaltsmöglichkeiten oberhalb der Ein- und Ausfahrten aufweist. An der obersten Spitze des Platz-Pavillons befindet sich ein öffentlicher Zugang zu der unterirdischen Einstellhalle, welche gleichzeitig an den Innenhof des gedeckten Platzes anknüpft. Der gesamte Platz ist schwellenfrei und bindet sich somit an die umliegende Nachbarschaft.",
    Entwurf: "2023",
    Referenz: "-",
    Auftrag: "Ideenwettbewerb, Kunsthaus Steffisburg",
  },
  {
    name: "Wohnen in Amsterdam",
    thumb: p03,
    images: [
      p0301,
      p0302,
      p0303,
      p0304,
      p0305,
      p0306,
      p0307,
      p0308,
      p0309,
      p0310,
      p0311,
      p0312,
      p0313,
    ],
    description:
      "Diese Aufgabe basiert auf ein referenzorientierter Entwurf. Meine Referenz ist Jacobus Johannes Pieter Oud und seine Terrassenhäuser. Der Entwurf befindet sich städtebaulich am Vondelpark in Amsterdam. Das Konzept besteht darin, drei öffentliche Durchgänge durch die Siedlung zu generieren, welche die Innenhöfe durchdringen. Eine Abterrassierung Richtung Park stärkt das Konzept und die Referenzierung.",
    Entwurf: "2018",
    Referenz: "Jacobus Johannes Pieter Oud, Terrassenhäuser",
    Auftrag: "Bachelor-Studium ZHAW",
  },
  {
    name: "Gartenstadt Winterthur",
    thumb: p04,
    images: [
      p0401,
      p0402,
      p0403,
      p0404,
      p0405,
      p0406,
      p0407,
      p0408,
      p0409,
      p0410,
    ],
    description:
      "Diese Aufgabe basiert auf ein referenzorientierter Entwurf. Meine Referenz ist Michael Alder mit der Wohnsiedlung Vogelbach in Riehen. Ich habe das Thema Schwellenraum als Eingangstor für meinen Entwurf übernommen. Die Überbauung befindet sich am Rande der Wülflingerstrasse. Der Rücken bildet eine städtebauliche Fassade zur Hauptstrasse und auf der anderen Seite einzelne Wohnhäuser, welche eine Verbindung durch die Schwellenräume zueinander herstellen. In der Mitte auf beiden Seiten bilden Aussenräume einen torartigen Eingang zu der bewusst proportionierten Innenhof, welche die Hauptattraktion der Anlage ist.",
    Entwurf: "2019",
    Referenz: "Michael Alder, Wohnsiedlung Vogelbach",
    Auftrag: "Bachelor-Studium ZHAW",
  },
  {
    name: "Haus der Stadtgeschichte",
    thumb: p02,
    images: [p0201, p0202, p0203, p0204, p0205, p0206, p0207],
    description:
      "An der Markgasse der Winterthur-Altstadt sollte ein Haus entstehen, welches die Geschichte dieser Stadt in sich trägt. Haupträume sowie ein Saal, Foyer und eine kleine Bibliothek gehören zu den Haupträumen. Mein Konzept bestand darin, die bestehenden Fassaden und die aussen liegenden Dächer zu erhalten, sodass im Inneren ein neues Haus entsteht (Palimpsest). Die bestehenden Fenster werden ausgefüllt. Möglichst geringe Öffnungen an den Fassaden lassen die nötige Belichtung in den Räumen hinein, da die Hauptbelichtung über die Dachöffnung stattfindet. Die Konstruktion basiert auf ein Holzknotenpunkt. In einem weiteren Schritt wird eine Fassade vertieft behandelt und verbessert. Hier wird der Kräfteverlauf in der bestehenden Fassade analysiert. Gleichzeitig werden zwei bestehende Fenster erhalten. Ein Fenstertypus bringt die beiden Fassaden zusammen, wodurch klar wird, dass hier ein einziges Haus entsteht. Das Konzept Palipsest lässt sich ebenfalls an der Innenfassade erkennen. Dachziegel sowie Dachsparren werden mehrheitlich erhalten und auf Mängel und Statik überprüft.",
    Entwurf: "2018",
    Referenz: "Palimpsest",
    Auftrag: "Bachelor-Studium ZHAW",
  },
  {
    name: "Campus Etas-Unis in Lyon",
    thumb: p06,
    images: [p0601, p0602, p0603, p0604, p0605, p0606, p0607, p0608, p0609],
    description:
      "In der Gruppe sind wir in drei unterschiedlichen Fakultäten eingeteilt, welche sowohl städtebaulich als auch funktional zusammen als Ensemble gelesen werden. Die drei Projekte sind ein Auditorium, eine Mensa und eine Bibliothek, wobei ich für das Auditorium zuständig bin. Die neuen Fakultäten nehmen die Strassenflucht der Cité industrielle von Tony Garnier auf und bilden einen Abschluss an der prominenten Strassenkreuzung. Es wird eine Verdichtung des Bauplatzes geben, eine Art Stadtreparatur, welche mit der bestehenden Schulanlage eine Überlagerung und Synergie bildet. Es werden drei eigenständige Gebäude geplant, die über ein Verbindungsgeschoss und einer Passerelle miteinander verbunden werden. Die drei Fakultäten reagieren mit den verschiedenen Dachterrassen auf den bestehenden Städtebau und bilden jeweils eine Beziehung zur nächsten Fakultät. Gleichzeitig generieren sie durch die Terrassen ein spannungsvolles Bild im Ensemble. Eingetreten ins Gebäude flaniert man bis zum Auditorium, welches sich im obersten Geschoss befindet. Die wohlsituierte Fakultät bietet hervorragende Ansätze für eine spannende Durchquerung der Geschosse mit umfangreichen Ausblicken und Versammlungsstellen. Beim Betreten des Gebäudes befindet man sich gleich im Atrium. Der kleine Saal, welcher auch als Sporthalle zum Einsatz kommt, ist zweigeschossig und ragt bis zum ersten Untergeschoss. Das Take-away befindet sich am Boulevard und die Verwaltung an der Hof-Seite. Direkt am Atrium angesetzt, befindet sich der Empfang und die Garderoben. Über die grosszügige Treppe landet man auf dem Vernetzungsgeschoss, wo die Fakultäten und die bestehende Primarschule zusammen treffen. Co-Working und Arbeitsräume auf diesem Geschoss sind öffentlich zugänglich. Weiter oben befinden sich Vorlesungssäle auf den Stockwerken und im obersten Geschoss kommt man zum Hauptfoyer des Auditoriums.",
    Entwurf: "2020",
    Referenz: "Rem Koolhaas, Botschaft der Niederlande in Berlin",
    Auftrag: "Bachelor-Studium ZHAW",
  },
];

export default allProjects;
