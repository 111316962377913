import { Drawer } from "@mui/material";
import React, { useRef, useState } from "react";
import constants from "../utils/constants";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  Close,
} from "@mui/icons-material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-creative";
import "swiper/css/pagination";
import "swiper/css/navigation";

const ProjectDrawer = ({ open, onClose, selectedProject = {} }) => {
  const drawerHeadingRef = useRef(null);

  const [prevEl, setPrevEl] = useState(null);
  const [nextEl, setNextEl] = useState(null);

  const slides =
    selectedProject?.images?.map((src, idx) => (
      <SwiperSlide key={idx + 1}>
        <img
          src={src}
          alt={idx + 1}
          style={{
            width: "100%",
            maxHeight: CSS.supports("height: 100svh") ? "100svh" : "100vh",
            minHeight: CSS.supports("height: 100svh") ? "100svh" : "100vh",
            // maxHeight: "100dvh",
            // minHeight: "100dvh",
            objectFit: "contain",
          }}
        />
      </SwiperSlide>
    )) ?? [];

  const moveDown = () => {
    drawerHeadingRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={onClose}
      SlideProps={{ appear: true }}
      transitionDuration={{ enter: 300, exit: 250 }}
    >
      <div className='project-drawer-content'>
        <Close className='close-button' onClick={onClose} />

        <div className='slider'>
          <Swiper
            loop
            grabCursor
            className='mySwiper'
            modules={[Pagination, Navigation]}
            pagination={{ clickable: true }}
            navigation={{ prevEl, nextEl }}
          >
            {slides}
          </Swiper>

          <ArrowForwardIosOutlined
            className='drawer-arrows arrow-down'
            onClick={moveDown}
          />
          <ArrowBackIosOutlined
            ref={(node) => setPrevEl(node)}
            className='drawer-arrows prev-arrow'
          />
          <ArrowForwardIosOutlined
            ref={(node) => setNextEl(node)}
            className='drawer-arrows next-arrow'
          />
        </div>

        <div
          ref={drawerHeadingRef}
          id='drawerHeading'
          className='project-drawer-text'
        >
          <h1 style={{ color: constants.colors.text }}>
            {selectedProject?.name}
          </h1>

          <div className='project-drawer-text-desc'>
            <p>{selectedProject?.description}</p>
            <p style={{ animationDelay: "0.45s" }}>
              <b>Entwurf: </b>
              {selectedProject?.Entwurf} <br />
              <b>Referenz: </b>
              {selectedProject?.Referenz}
              <br />
              <b>Auftrag: </b>
              {selectedProject?.Auftrag}
              <br />
            </p>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default ProjectDrawer;
