import React, { useEffect, useState } from "react";

const Loader = ({ isLoading }) => {
  const [displayLoader, setDisplayLoader] = useState(isLoading);

  useEffect(() => {
    if (isLoading) {
      setDisplayLoader(isLoading);
    } else {
      setTimeout(() => {
        setDisplayLoader(isLoading);
      }, 500);
    }
  }, [isLoading]);

  return (
    <div className={`loader ${displayLoader ? "" : "loader-hide"}`}>
      {/* <div className='loader-content-outer-box'>
        <div className='loader-content'>
          <h1 className='app-logo'>
            <span>
              <i>der</i>
            </span>
            Haus
          </h1>
        </div>
      </div> */}
    </div>
  );
};

export default Loader;
