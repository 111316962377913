import { Instagram, Menu } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import React, { useEffect, useState } from "react";

const Links = {
  Aktuell: "Aktuell",
  Projekte: "Projektes",
  Portrait: "Portrait",
  Kontakt: "Kontakt",
};

const Navbar = ({ isLoading }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(isLoading);

  useEffect(() => {
    if (isLoading) setDisplayLoader(isLoading);
    else setTimeout(() => setDisplayLoader(isLoading), 980);
  }, [isLoading]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const MenuLinks = (addAnimations) => (
    <ul
      className={`menu-links ${
        addAnimations && !displayLoader ? "menu-animations" : ""
      }`}
    >
      {/* <li style={{ animationDelay: 0 }}>
        <a href={`#${Links.Aktuell}`} onClick={toggleMenu}>
          Aktuell
        </a>
      </li> */}
      <li style={{ animationDelay: "0.075s" }}>
        <a href={`#${Links.Projekte}`} onClick={toggleMenu}>
          Projekte
        </a>
      </li>
      <li style={{ animationDelay: "0.15s" }}>
        <a href={`#${Links.Portrait}`} onClick={toggleMenu}>
          Portrait
        </a>
      </li>
      <li style={{ animationDelay: "0.225s" }}>
        <a href={`#${Links.Kontakt}`} onClick={toggleMenu}>
          Kontakt
        </a>
      </li>
    </ul>
  );

  return (
    <header className='navbar'>
      <div className='navbar-top-bar'>
        <span className='navbar-logo-container'>
          <a
            href='https://www.instagram.com/derhaus.ch/'
            target='_blank'
            rel='noopener noreferrer'
            className={`logo ${!displayLoader ? "logo-animation" : ""}`}
          >
            <Instagram />
          </a>

          <a
            href={`#${Links.Aktuell}`}
            className={`app-logo  ${!displayLoader ? "logo-animation" : ""}`}
          >
            <span>
              <i>der</i>
            </span>
            Haus
          </a>
        </span>

        <Menu
          className={`hamburger ${!displayLoader ? "hamburger-animation" : ""}`}
          onClick={toggleMenu}
        />
      </div>

      <Collapse in={isMenuOpen} timeout={"auto"}>
        {MenuLinks(isMenuOpen)}
      </Collapse>
    </header>
  );
};

export default Navbar;
