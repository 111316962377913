import React, { useRef } from "react";
import { avatar1 } from "../utils/imagesPaths";
import constants from "../utils/constants";
import useSectionInView from "../hooks/useSectionInView";

const Portrait = ({ updateLoadedImages }) => {
  const portraitRef = useRef();
  const { appear } = useSectionInView(portraitRef);

  return (
    <section
      id='Portrait'
      ref={portraitRef}
      className={`${
        appear ? "portrait-animations" : "portrait-animations-out"
      }`}
    >
      <div>
        <h2 className='portrait-heading' style={{ animationDelay: "0.05s" }}>
          Portrait
        </h2>

        <div className='portrait-description'>
          <p style={{ animationDelay: "0.1s" }}>
            Willkommen auf meiner Architektur-Website. Hier teile ich meine
            Leidenschaft für Architektur und meinen Ansatz, sie auf besondere
            Weise zu gestalten. <br />
            <br />
            Als Architekt strebe ich danach, die Welt um uns herum auf neue und
            kreative Weise zu gestalten. In meinen Entwürfen vereine ich
            geschickt Form, Funktion und Ästhetik. Ich experimentiere gerne mit
            verschiedenen Spannungen, um innovative Konzepte zu entwickeln, die
            die Sinne ansprechen und zum Nachdenken anregen. Meine Arbeit
            zeichnet sich durch Liebe zum Detail und kreative Lösungen aus.
            Jedes Projekt, dem ich mich widme, plane ich mit Hingabe und
            Präzision, um den individuellen Bedürfnissen und Visionen der Nutzer
            gerecht zu werden. Ich glaube fest daran, dass gute Architektur die
            Lebensqualität verbessern kann und engagiere mich dafür,
            einzigartige und inspirierende Räume zu schaffen. Dabei ist es mir
            wichtig, den Umgang mit Baustoffen verantwortungsbewusst zu
            gestalten.{" "}
          </p>
          <p style={{ animationDelay: "0.15s" }}></p>
        </div>
      </div>

      <div
        className={`box ${appear ? "box-animations" : "box-animations-out"}`}
      >
        <div className='Team' style={{ animationDelay: "0.2s" }}>
          <img
            src={avatar1}
            alt='avatar'
            onLoad={() =>
              updateLoadedImages((currentState) => (currentState += 1))
            }
          />
        </div>
      </div>

      <div
        className={`box ${appear ? "box-animations" : "box-animations-out"}`}
      >
        <div className='Team' style={{ animationDelay: "0.3s" }}>
          <div className='text-box'>
            <h4 className="team-member-name">Akam Abdolrauf Ismaili</h4>
            <p>
              Inhaber <font color={constants.colors.primary}>|</font> Architekt
              BA ZFH
            </p>
            <p className="cv">
              <br />
              23<font color={constants.colors.primary}>-</font>25 Architektur
              <font color={constants.colors.primary}>-</font>Studium MA ZHAW
              <br />
              23 selbstständige Tätigkeiten
              <br />
              21<font color={constants.colors.primary}>-</font>23 Projektleiter{" "}
              <font color={constants.colors.primary}>|</font> Diagonal
              Architekten
              <br />
              20<font color={constants.colors.primary}>-</font>21 Projektleiter{" "}
              <font color={constants.colors.primary}>|</font> mml Architekten
              <br />
              17<font color={constants.colors.primary}>-</font>20 Architektur
              <font color={constants.colors.primary}>-</font>Studium BA ZHAW
              <br />
              12<font color={constants.colors.primary}>-</font>16 Lehre Zeichner
              Architektur EFZ
              <br />
              04 Schweiz
              <br />
              89 Iran
              <br />
              <br />
              Entwurf <font color={constants.colors.primary}>|</font>{" "}
              Konzeptstudie <font color={constants.colors.primary}>|</font>{" "}
              Variantenstudie <font color={constants.colors.primary}>|</font>{" "}
              Projektleitung
              <br />
              Modellbau <font color={constants.colors.primary}>|</font> 2D{" "}
              <font color={constants.colors.primary}>&</font> 3D Planung{" "}
              <font color={constants.colors.primary}>|</font> Visualisierung
              <br />
            </p>
          </div>
        </div>
        {/* 

        <div className='Team' style={{ animationDelay: "0.25s" }}>
          <img
            src={avatar2}
            alt='avatar-2'
            // onLoad={() =>
            //   updateLoadedImages((currentState) => (currentState += 1))
            // }
          />
          <div className='text-box'>
            <h4>Qendrim Sadikaj</h4>
            <p>
              assoziiert <font color={constants.colors.primary}>|</font> dipl.
              Techniker HF
            </p>
            <p>
              <br />
              2023 ... selbstständige Tätigkeiten
              <br />
              2021<font color={constants.colors.primary}>-</font>2023 ...
              Bauleitung<font color={constants.colors.primary}> & </font>
              Berufsbildner <font color={constants.colors.primary}>|</font>{" "}
              Architektengruppe 4<br />
              2018<font color={constants.colors.primary}>-</font>2021 ...
              Techniker Studium HF Winterthur
              <br />
              2017<font color={constants.colors.primary}>-</font>2018 ...
              Zeichner <font color={constants.colors.primary}> & </font>{" "}
              Berufsbildner <font color={constants.colors.primary}>|</font>{" "}
              Architektengruppe 4<br />
              2012<font color={constants.colors.primary}>-</font>2016 ... Lehre
              Zeichner Architektur EFZ
              <br />
              2000<font color={constants.colors.primary}>-</font>2012 ...
              Schulbildung Pontresina GR
              <br />
              2000 ... Ankunft in der Schweiz
              <br />
              1996 ... Geboren in Kosovo
              <br />
              
              <br />
              Strategische Planung{" "}
              <font color={constants.colors.primary}>
                /
              </font> Machbarkeitsstudie{" "}
              <font color={constants.colors.primary}>|</font> Kostenermittlung{" "}
              <font color={constants.colors.primary}>|</font> <br />
              Ausschreibung <font color={constants.colors.primary}>|</font>{" "}
              Detailstudien <font color={constants.colors.primary}>&</font>{" "}
              Ausführungsplanung <font color={constants.colors.primary}>|</font>{" "}
              Realisierung
              <br />
            </p>
          </div>
        </div>
        */}
      </div>
    </section>
  );
};

export default Portrait;
