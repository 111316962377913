import React, { useRef } from "react";
import useSectionInView from "../hooks/useSectionInView";

const Footer = () => {
  const contactRef = useRef();
  const { appear } = useSectionInView(contactRef);

  return (
    <footer className={appear ? "footer-animations" : "footer-animations-out"}>
      <a href='#Impressum'>
        Impressum &copy; 2023 derHaus Ismaili
      </a>
    </footer>
  );
};

export default Footer;
