import React, { useEffect, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import Projects from "../components/Projects";
import Portrait from "../components/Portrait";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Impressum from "../components/Impressum";
import ParallaxHero from "../components/ParallaxHero";
import * as allImages from "../utils/imagesPaths";
import "../styles/HomeStyles.css";
import "../styles/font/fonts.css";

const NewHome = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedImages, setLoadedImages] = useState(0);

  useEffect(() => {
    // if (loadedImages === Object.values(allImages).length)
    if (loadedImages === Object.values(allImages).length - 1)
      setTimeout(() => setIsLoading(false), 100);
    else setTimeout(() => setIsLoading(false), 5000);
  }, [loadedImages]);

  return (
    <>
      <ParallaxProvider>
        <Loader isLoading={isLoading} />
        <div style={{ display: isLoading ? "none" : "unset" }}>
          <Navbar isLoading={isLoading} />
          {/* <ParallaxHero
            isLoading={isLoading}
            updateLoadedImages={setLoadedImages}
          /> */}
          <Projects updateLoadedImages={setLoadedImages} />
          <Portrait updateLoadedImages={setLoadedImages} />
          <Contact />
          <Footer />
          <Impressum />
        </div>
      </ParallaxProvider>
    </>
  );
};

export default NewHome;
