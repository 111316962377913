import React, { useRef, useState } from "react";
import ProjectDrawer from "./ProjectDrawer";
import allProjects from "../utils/allProjects";
import useSectionInView from "../hooks/useSectionInView";
import { useMediaQuery } from "@mui/material";

const Projects = ({ updateLoadedImages }) => {
  const projectsRef = useRef();
  const { appear } = useSectionInView(projectsRef);
  const isSmallScreen = useMediaQuery("(max-width:1024px)");

  const [selectedProject, setSelectedProject] = useState(null);

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  const columns = {
    FIRST: [],
    SECOND: [],
    THIRD: [],
  };

  if (!isSmallScreen)
    allProjects.forEach((pro, idx) => {
      if (idx % 3 === 0) columns.FIRST.push(pro);
      else if (idx % 3 === 1) columns.SECOND.push(pro);
      else if (idx % 3 === 2) columns.THIRD.push(pro);
    });

  return (
    <>
      <section
        ref={projectsRef}
        id='Projektes'
        className={"projektes-animations"}
        // className={appear ? "projektes-animations" : "projektes-animations-out"}
      >
        {!isSmallScreen
          ? Object.values(columns).map((cols, colIdx) => (
              <div className='column' key={colIdx}>
                {cols.map((project, idx) => (
                  <span
                    key={project.name}
                    className='image-overlay-container'
                    onClick={() => handleSelectProject(project)}
                    style={{
                      animationDelay: `${
                        (!idx
                          ? colIdx === 0
                            ? 1
                            : colIdx === 1
                            ? 2
                            : 3
                          : idx + (colIdx === 0 ? 3 : colIdx === 1 ? 4 : 5)) /
                        12
                      }s`,
                    }}
                  >
                    <img
                      alt={project.name}
                      src={project.thumb}
                      onLoad={() =>
                        updateLoadedImages(
                          (currentState) => (currentState += 1)
                        )
                      }
                    />
                    <span className='image-overlay' />
                  </span>
                ))}
              </div>
            ))
          : allProjects.map((project, idx) => (
              <span
                key={project.name}
                className='image-overlay-container'
                onClick={() => handleSelectProject(project)}
                style={{ animationDelay: `${(idx + 1) / 12}s` }}
              >
                <img
                  alt={project.name}
                  src={project.thumb}
                  onLoad={() =>
                    updateLoadedImages((currentState) => (currentState += 1))
                  }
                />

                <span className='image-overlay' />
              </span>
            ))}
      </section>

      <ProjectDrawer
        open={!!selectedProject}
        onClose={() => setSelectedProject(null)}
        selectedProject={selectedProject}
      />
    </>
  );
};

export default Projects;
